import React from "react";

function Tarifas() {
  return (
    <section
      id="tarifas"
      className="lg:m-auto lg:mt-24 lg:mb-24 lg:w-fit m-4  bg-green-50 scroll-mt-32 rounded-md text-green-950"
    >
      <h2 className="font-bold text-3xl lg:text-4xl text-center  py-6">
        Tarifas:
      </h2>
      <div className="m-auto">
        <div className="flex items-center">
          <img
            className="m-6"
            src="https://img.icons8.com/ios/50/cottage--v1.png"
            alt="cabaña dormi"
            width={60}
            height={60}
          />
          <h2 className="m-4">
            <span className="text-xl font-semibold">Cabaña:</span> AR$65.000.
          </h2>
        </div>
        <div className="flex items-center">
          <img
            className="m-6"
            src="https://img.icons8.com/material-outlined/60/null/cottage.png"
            alt="cabaña dormi"
          />
          <h2 className="m-4">
            <span className="text-xl font-semibold">Dormi para 5:</span>{" "}
            AR$45.000.
          </h2>
        </div>
        <div className="flex items-center">
          <img
            className="m-6"
            src="https://img.icons8.com/ios/60/null/camping-tent.png"
            alt="camping carpa"
          />
          <div>
            <h2 className="m-4">
              <span className="text-xl font-semibold">Camping:</span> AR$9.000
              (por persona, no se cobra carpa).
            </h2>
          </div>
        </div>
        <div className="flex items-center">
          <img
            src="https://img.icons8.com/ios/60/null/sedan.png"
            alt="auto"
            className="m-6"
          />
          <h2 className="m-4">
            <span className="text-xl font-semibold">Auto:</span> AR$3.000.
          </h2>
        </div>
      </div>
    </section>
  );
}

export default Tarifas;
